import React, { FC } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'

import {
  Breadcrumb,
  BreadcrumbItem,
  ContentArea,
  PageTitle,
  TitleBorder
} from '@bscs-dev-team/bscs-design-system-core'

import {
  Frontmatter,
  TemplateProps
} from './template-types'

import Layout from '../components/layout'
import SEO from '../components/seo'


const LeadershipTemplate: FC<TemplateProps> = ({ data }: TemplateProps) => {
  const html: unknown = data.mdx.body
  const person: Frontmatter = data.mdx.frontmatter

  return (
    <React.Fragment>
      <SEO
        title={ person.fullName }
        canonicalUrl={ person.canonicalUrl }
        description={ person.seoDescription }
        lang={ person.seoLang }
      />
      <Layout>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/'>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            About
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link className='inline-anchor' to='/about/leadership'>Leadership</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            {person.fullName}
          </BreadcrumbItem>
        </Breadcrumb>
        <ContentArea size="xl" className="mt-5">
          <div className="flex">
            <Img
              alt={person.alt}
              className="h-32 w-32 rounded-full"
              fluid={person.image.childImageSharp.fluid}
              imgStyle={{ objectPosition: "top" }}
            />
            <div className="ml-3 self-end">
              <PageTitle>{person.fullName}</PageTitle>
              <TitleBorder variant='blue' />
            </div>
          </div>
          <MDXRenderer>{ html }</MDXRenderer>
        </ContentArea>
      </Layout>
    </React.Fragment>
  )
}

export default LeadershipTemplate

export const query = graphql`
  query($nodeId: String!) {
    mdx(id: {eq: $nodeId}) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        canonicalUrl
        seoDescription
        seoLang
        additionalTags
        fullName
        type
        alt
        areas
        image {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        template
        title
      }
    }
  }
`
